import { AnyAction } from 'redux';

import { MenuItem } from 'Query/Menu.type';

export enum MenuActionType {
    GET_MENU = 'GET_MENU',
}

export interface SetIsMenuAction extends AnyAction {
    type: MenuActionType.GET_MENU;
    menu: MenuItem[];
}

export type MenuAction =
    SetIsMenuAction;

export interface MenuStore {
    menu: MenuItem[];
}
