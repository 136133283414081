import { Reducer } from 'redux';

import { MenuAction, MenuActionType, MenuStore } from './Menu.type';

export const CART_TOTALS = 'cart_totals';

/** @namespace Pwa/Store/Menu/Reducer/getInitialState */
export const getInitialState = (): MenuStore => ({
    menu: [],
});

/** @namespace Pwa/Store/Menu/Reducer/MenuReducer */
export const MenuReducer: Reducer<MenuStore, MenuAction> = (
    state = getInitialState(),
    action,
) => {
    const { type } = action;

    switch (type) {
    case MenuActionType.GET_MENU:
        const { menu } = action;

        return {
            ...state,
            menu,
        };
    default:
        return state;
    }
};

export default MenuReducer;
