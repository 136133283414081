import { Reducer } from 'redux';

import {
    CheckoutAction,
    CheckoutActionType,
    CheckoutStore,
} from './Checkout.type';

/** @namespace Pwa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = (): CheckoutStore => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    isCheckout: false,
    isMenu: false,
});

/** @namespace Pwa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer: Reducer<
CheckoutStore,
CheckoutAction
> = (
    state = getInitialState(),
    action,
) => {
    switch (action.type) {
    case CheckoutActionType.UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields,
        };

    case CheckoutActionType.UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email,
        };

    case CheckoutActionType.UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable,
        };

    case CheckoutActionType.SET_IS_CHECKOUT:
        const { isCheckout } = action;

        return {
            ...state,
            isCheckout,
        };

    case CheckoutActionType.SET_IS_MENU:
        const { isMenu } = action;

        return {
            ...state,
            isMenu,
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
