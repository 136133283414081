import { AnyAction } from 'redux';

export enum CheckoutActionType {
    UPDATE_SHIPPING_FIELDS = 'UPDATE_SHIPPING_FIELDS',
    UPDATE_EMAIL = 'UPDATE_EMAIL',
    UPDATE_EMAIL_AVAILABLE = 'UPDATE_EMAIL_AVAILABLE',
    SET_IS_CHECKOUT = 'SET_IS_CHECKOUT',
    SET_IS_MENU = 'SET_IS_MENU',
}

export interface UpdateShippingFieldsAction extends AnyAction {
    type: CheckoutActionType.UPDATE_SHIPPING_FIELDS;
    shippingFields: Record<string, unknown>;
}
export interface UpdateEmailAction extends AnyAction {
    type: CheckoutActionType.UPDATE_EMAIL;
    email: string;
}
export interface UpdateEmailAvailableAction extends AnyAction {
    type: CheckoutActionType.UPDATE_EMAIL_AVAILABLE;
    isEmailAvailable: boolean;
}

export interface SetIsCheckoutAction extends AnyAction {
    type: CheckoutActionType.SET_IS_CHECKOUT;
    isCheckout: boolean;
}

export interface SetIsMenuAction extends AnyAction {
    type: CheckoutActionType.SET_IS_MENU;
    isMenu: boolean;
}

export type CheckoutAction =
    UpdateShippingFieldsAction |
    UpdateEmailAction |
    SetIsCheckoutAction |
    SetIsMenuAction |
    UpdateEmailAvailableAction;

export interface CheckoutStore {
    shippingFields: Record<string, unknown>;
    email: string;
    isEmailAvailable: boolean;
    isCheckout: boolean;
    isMenu: boolean;
}

export interface CheckoutDispatcherData {
    isEmailAvailable: {
        is_email_available: boolean;
    };
}
