import {
    LoaderComponent as SourceLoaderComponent,
} from 'SourceComponent/Loader/Loader.component';

import './Loader.override.style';

/** @namespace Pwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoaderComponent {
    // TODO implement logic
}

export default LoaderComponent;
